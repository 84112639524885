import React, { useState } from 'react'
import { getSlug } from '../../../helper'
import { useSearchUseCase } from '../../../hooks/useSearchUseCase'
import Autosuggest from 'react-autosuggest'

const SearchUseCaseBlock = ({ headline, subHeadline, col6, useCaseh1Current }) => {

    const data = useSearchUseCase()

    const { allWpCptUseCase } = data
    const [useCaseSelected, setUseCaseSelected] = useState()
    const [value, setValue] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const usecaseSlug = getSlug(allWpCptUseCase.nodes[0].uri)

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : allWpCptUseCase.nodes.filter(useCase => {
            const searchUseCase = useCase.cfUseCase.mainElements.excerptTitle ?
                (useCase.cfUseCase.mainElements.h1.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                    useCase.cfUseCase.mainElements.excerptTitle.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) :
                useCase.cfUseCase.mainElements.h1.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            if (searchUseCase && useCase.cfUseCase.mainElements.h1 !== useCaseh1Current
            ) return true
            return false
        })
    };

    const getSuggestionValue = suggestion => suggestion.cfUseCase.mainElements.excerptTitle ?
        suggestion.cfUseCase.mainElements.excerptTitle :
        suggestion.cfUseCase.mainElements.h1;

    const onChange = (event, { newValue }) => {
        setValue(newValue)
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    }

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    const onSuggestionHighlighted = ({ suggestion }) => {
        if (suggestion) {
            const useCaseName = suggestion.cfUseCase.mainElements.excerptTitle ?
                suggestion.cfUseCase.mainElements.excerptTitle :
                suggestion.cfUseCase.mainElements.h1
            const useCaseSuggestion = {
                'useCaseName': useCaseName,
                'useCaseSlug': `/${usecaseSlug}/${suggestion.cfUseCase.mainElements.slug}/`
            }
            setUseCaseSelected(useCaseSuggestion)
        } else {
            setUseCaseSelected(useCaseSelected)
        }
    }

    const renderSuggestion = suggestion => {
        const useCaseName = suggestion.cfUseCase.mainElements.excerptTitle ?
            suggestion.cfUseCase.mainElements.excerptTitle :
            suggestion.cfUseCase.mainElements.h1
        return (
            <div>
                {useCaseName}
            </div>
        )
    }

    const inputProps = {
        placeholder: "Enter Use Case...",
        value,
        onChange: onChange,
    }

    const handleClickSearch = () => {
        if (typeof window != 'undefined') {
            window.location.assign(useCaseSelected.useCaseSlug);
        }
    }

    const onSuggestionSelected = () => {
        if (typeof window != 'undefined') {
            window.location.assign(useCaseSelected.useCaseSlug)
        }
    }

    return (
        <div className="use-case-search form-wrapper search">
            <div className="row">
                <div className={`${col6 ? 'col-lg-6' : 'col-md-4 col-lg-3'}`}>
                    {headline && (
                        <div className="headline-form">
                            <h2>{headline}</h2>
                            {
                                subHeadline && (
                                    <p>{subHeadline}</p>
                                )
                            }
                        </div>
                    )}
                </div>
                <div className={`${col6 ? 'col-lg-6' : 'col-md-8 col-lg-9'}`}>
                    <div className="combobox-wrapper">
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                            onSuggestionHighlighted={onSuggestionHighlighted}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            alwaysRenderSuggestions={false}
                            onSuggestionSelected={onSuggestionSelected}
                        />
                        <button className="button" onClick={handleClickSearch} disabled={useCaseSelected && inputProps.value === useCaseSelected.useCaseName ? '' : 'disabled'} >Search</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchUseCaseBlock
