import { useStaticQuery, graphql } from 'gatsby'

export const useSearchUseCase = () => {
    const data = useStaticQuery(graphql`
        query SearchUseCaseBlock {
            allWpCptUseCase {
                nodes {
                    ...WordpressCPTUseCaseRelatedFields
                }
            }

        }
    `)
    return data
}
